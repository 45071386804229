import React, { useState } from 'react';
import { Button, Paper, Box, Typography } from '@mui/material';

const CodeDisplay = ({ codeSnippet }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(codeSnippet);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box sx={{ p: 2, pl: 0, zIndex: 3, minWidth: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Exemplo de Código:
      </Typography>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          borderRadius: 2,
          border: 'solid 1px #666',
          color: '#ccc',
          backgroundColor: '#222',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' }, // Ajusta para coluna em telas pequenas
        }}
      >
        <Box
          component="pre"
          sx={{
            flex: 1,
            whiteSpace: 'pre-wrap', // Quebra de linha automática para evitar overflow em telas pequenas
            wordBreak: 'break-word', // Quebra palavras grandes
            maxWidth: { xs: '100%', md: '75%' }, // Ajusta a largura para diferentes tamanhos
            overflow: 'auto',
          }}
        >
          <code>{codeSnippet}</code>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCopy}
          sx={{
            mt: { xs: 2, md: 0 }, // Margem superior para telas pequenas
            ml: { md: 2 }, // Margem esquerda para telas maiores
            alignSelf: { xs: 'center', md: 'flex-start' },
          }}
        >
          {copied ? 'Copiado!' : 'Copiar Código'}
        </Button>
      </Paper>
    </Box>
  );
};

export default CodeDisplay;
