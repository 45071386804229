import React, { useState } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, Tooltip } from '@mui/material';
import { Icon } from '@mdi/react';
import { AboutsConfig } from '../../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/logo512.png';

const SidebarMenu = ({ onOptionClick, isMobile }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (path) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        navigate(path);
        onOptionClick(); // Fecha o menu ao clicar em uma opção
    };

    return (
        <Box
            sx={{
                zIndex: 2,
                width: isMobile ? '235px' : '50px', // Altera a largura com base no tamanho do dispositivo
                height: '100%', // Permite que a altura se ajuste ao conteúdo
                position: 'fixed',
                transition: 'width 0.3s',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto', // Permite que o conteúdo do menu seja rolável
                // paddingBottom: '16px',
                scrollbarWidth: 'none', // Esconde barra de rolagem no Firefox
                '&::-webkit-scrollbar': { display: 'none' }, // Esconde barra de rolagem no Chrome/Safari
                backgroundImage: 'url(https://i.ibb.co/TH28T9y/DALL-E-2024-10-05-14-02-41-A-sleek-futuristic-sidebar-menu-background-for-a-technology-themed-websit.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <List sx={{ backgroundColor: '#3C4043' }}>
                {/* Logotipo */}
                <ListItem
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isMobile ? 'flex-start' : 'center',
                        padding: '10px',
                        paddingLeft: isMobile ? '0px' : '5px',
                        backgroundColor: 'rgba(000, 000, 000, 0.6)',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: '40px',
                            color: '#fff',
                        }}
                    >
                        <Box
                            component="img"
                            src={logo}
                            height={45}
                            width={45}
                            style={{
                                marginRight: 5,
                                marginLeft: 10,
                                marginBottom: 5,
                                marginTop: 5,
                                borderRadius: 60,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                border: '2px solid',
                                borderImageSlice: 1,
                                borderWidth: '1.5px',
                                borderImageSource: 'linear-gradient(35deg, #dc004e, #3333ff)',
                            }}
                        />
                    </ListItemIcon>
                    {isMobile && (
                        <ListItemText
                            primary="Condor Digital"
                            primaryTypographyProps={{
                                color: '#fff',
                                whiteSpace: 'nowrap',
                                fontSize: 24,
                                fontWeight: 600,
                            }}
                        />
                    )}
                </ListItem>

                {/* Mapeamento das opções do menu */}
                {AboutsConfig.map((item, index) => (
                    <Tooltip title={item.label} key={item.label} arrow>
                        <ListItem
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: isMobile ? 'flex-start' : 'center',
                                padding: '10px',
                                cursor: 'pointer',
                                borderTop: '1px solid #322229',
                                backgroundColor: location.pathname === item.path ? '#3C4043' : 'rgba(000, 000, 000, 0.6)',
                                '&:hover': { backgroundColor: '#3C4043' },
                            }}
                            onClick={() => handleClick(item.path)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: '30px',
                                    minHeight: '30px',
                                    transform: hoveredIndex === index ? 'scale(1.3)' : 'scale(1)',
                                    transition: 'transform 0.3s ease',
                                    color: '#fff',
                                    borderRadius: 10,
                                }}
                            >
                                <Icon path={item.icon} size={1.2} color={location.pathname === item.path ? '#dc004e' : 'white'} />
                            </ListItemIcon>
                            {isMobile && (
                                <ListItemText
                                    primary={item.label}
                                    sx={{ ml: 2, transition: 'opacity 0.3s ease', whiteSpace: 'nowrap' }}
                                    primaryTypographyProps={{
                                        color: location.pathname === item.path ? '#dc004e' : 'white',
                                        fontWeight: location.pathname === item.path ? 500 : 'normal',
                                        fontSize: location.pathname === item.path ? 17 : 16,
                                    }}
                                />
                            )}
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
        </Box>
    );
};

export default SidebarMenu;
