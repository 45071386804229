import { useMediaQuery } from '@mui/material';
import React from 'react';

const VideoPlayer = ({ src, title }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <iframe
                width={isMobile ? "350px" : "900px"}
                height={isMobile ? "300px" : "600px"}
                src={src}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default VideoPlayer;
