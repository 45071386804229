import Icon from "@mdi/react";
import { Box, Typography } from "@mui/material";

const Title = ({ icon, text }) => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, pl: 2 }}>
            <Icon path={icon} size={5} style={{ color: 'white' }} />
            <Typography variant="h2" color="white">
                {text}
            </Typography>
        </Box>
    )
}

export default Title;