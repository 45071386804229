import { Box, Typography, List, ListItem, Card, CardMedia, CardContent, Button, useMediaQuery } from "@mui/material";
import CodeDisplay from '../../../CodeDisplay/CodeDisplay';
import { Link } from "react-router-dom";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";

const Topics = ({ data }) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            {data?.topics?.map((item) => {
                if (data.label === 'Notícias') {
                    return (
                        <Box key={item.label} sx={{ marginBottom: 4 }}>
                            <Typography variant="h4" color="white" sx={{ pl: 2 }}>
                                {item.label}
                            </Typography>
                            <Typography variant="body1" color="white" sx={{ marginBottom: 2, pl: 2 }}>
                                {item.description}
                            </Typography>

                            {item.subtopics?.map((subtopic, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        marginBottom: 4,
                                        backgroundColor: 'rgba(000, 000, 000, 0.6)',
                                        borderRadius: '8px',
                                        padding: 2,
                                    }}
                                >
                                    <Typography variant="h5" color="white" sx={{ pl: 2 }}>
                                        {subtopic.primary}
                                    </Typography>
                                    <Typography variant="body2" color="white" sx={{ marginBottom: 2, pl: 2 }}>
                                        {subtopic.secondary}
                                    </Typography>
                                    {subtopic.image && (
                                        <>
                                            <CardMedia
                                                component="img"
                                                sx={{ maxWidth: '100%', height: 'auto', marginBottom: 1, borderRadius: 8 }}
                                                image={subtopic.image}
                                                alt={subtopic.alt}
                                            />
                                            {subtopic.caption && (
                                                <Typography variant="h6" color="white" sx={{ bgcolor: '#013482', borderRadius: 2, textAlign: 'center', marginBottom: 2 }}>
                                                    {subtopic.caption}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {subtopic.video && (
                                        <VideoPlayer src={subtopic.video} alt={subtopic.title}/>
                                    )}
                                    {subtopic.link && (
                                        <Box width="100%" display="flex" justifyContent="flex-end">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                href={subtopic.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Leia mais no site oficial
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    );
                }

                return (
                    <Box
                        key={item.label}
                        sx={{
                            marginBottom: 4,
                            pr: isMobile ? 0 : 10,
                            backgroundColor: 'rgba(000, 000, 000, 0.2)',
                            borderRadius: '8px',
                            padding: 2,
                        }}
                    >
                        <Typography variant="h4" color="white" sx={{ pl: 2 }}>
                            {item.label}
                        </Typography>
                        <Typography variant="body1" color="white" sx={{ marginBottom: 2, pr: isMobile ? 0 : 10, pl: 2 }}>
                            {item.description}
                        </Typography>

                        {item.subtopics && (
                            <List>
                                {item.subtopics.map((subtopic, index) => (
                                    <ListItem key={index} sx={{ pl: 0, pr: 0, m: 0 }}>
                                        <Card
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', md: 'row' },
                                                color: 'white',
                                                minWidth: '100%',
                                                alignItems: 'center',
                                                backgroundColor: 'rgba(000, 000, 000, 0.6)',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <CardContent>
                                                <Typography component="h5" variant="h5" sx={{ pl: 2, pr: 3, marginBottom: 2 }}>
                                                    {subtopic.primary}
                                                </Typography>
                                                <Typography sx={{ fontSize: 16, pl: 2, pr: 3, marginBottom: 2 }} color="white">
                                                    {subtopic.secondary}
                                                </Typography>

                                                {subtopic.codeSnippet && <CodeDisplay codeSnippet={subtopic.codeSnippet} />}
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                );
            })}
        </>
    );
};

export default Topics;
