import Root from "./fragments/AboutRoot";
import Title from "./fragments/AboutTitle";
import Topics from "./fragments/AboutTopics";

const About = {
    Root: Root,
    Title: Title,
    Topics: Topics,
}

export default About;