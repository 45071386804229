import { Box, useMediaQuery,  } from "@mui/material";

const Root = ({ children }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Box
            sx={{
                // opacity: 0.98,
                // backgroundColor: '#13212C',
                minHeight: '100vh',
                pr: isMobile ? 3 : 4, 
                pl: isMobile ? 1 : 4,
                ml: isMobile ? -5 : 4,
                paddingTop: 4,
                minWidth: 350,
            }}
        >
            {children}
        </Box>
    )
}

export default Root;