import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

const themeDefault = createTheme({
  palette: {
    primary: {
      main: '#1976d2',  // Cor primária
    },
    secondary: {
      main: '#dc004e',  // Cor secundária
    },
    background: {
      default: '#eef2f3', // Cor de fundo padrão
      paper: 'linear-gradient(to top, #8e9eab, #eef2f3)', // Cor de fundo do papel
    },
    text: {
      primary: '#333', // Cor do texto primário
      secondary: '#666', // Cor do texto secundário
    }
  },
  typography: {
    fontFamily: [
      'Raleway',
    ].join(','),
    h1: {
      fontFamily: 'Raleway, serif',
      fontWeight: 600,
      fontSize: '2.5rem',
    },
    h2: {
      fontFamily: 'Raleway, serif',
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Raleway, serif',
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h4: {
      fontFamily: 'Raleway, serif',
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h5: {
      fontFamily: 'Raleway, serif',
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h6: {
      fontFamily: 'Raleway, serif',
      fontWeight: 600,
      fontSize: '1rem',
    },
    subtitle1: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
    },
    subtitle2: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    body1: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    button: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 600,
      fontSize: '0.875rem',
    },
    caption: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
    },
    overline: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeDefault}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals(sendToVercelAnalytics);
