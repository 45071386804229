import React, { useState } from 'react';
import { Box, IconButton, Drawer, useMediaQuery, Tooltip, GlobalStyles } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SidebarMenu from './components/SideBarMenu/SideBarMenu';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AboutsConfig } from './constants';
import AboutComponent from './components/PagesComponent/AboutComponent/AboutComponent';

const App = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [menuOpen, setMenuOpen] = useState(false); // Estado de controle do menu

    const toggleMenu = () => setMenuOpen((prev) => !prev);

    const handleMenuOptionClick = () => {
        setMenuOpen(false); // Fecha o menu ao clicar em uma opção
    };

    return (
        <Router>
            <Box sx={{ position: 'relative', minHeight: '100vh', backgroundColor: '#292D2E', overflow: 'hidden', }}>
                <GlobalStyles
                    styles={{
                        '::-webkit-scrollbar': { width: '10px' },
                        '::-webkit-scrollbar-track': { background: '#292D2E' },
                        '::-webkit-scrollbar-thumb': {
                            background: '#dc004e',
                            '&:hover': { background: '#253035' },
                        },
                    }}
                />

                <Box
                    component="img"
                    src="https://i.ibb.co/s5jQ6LH/DALL-E-2024-10-05-13-14-18-A-futuristic-technology-themed-website-background-inspired-by-circuit-boa.webp"
                    sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0, opacity: 0.6 }}
                />

                {/* Botão de Menu para Dispositivos Móveis */}
                {isMobile && (
                    <Tooltip title="Menu" arrow>
                        <IconButton
                            onClick={toggleMenu}
                            sx={{ position: 'fixed', top: 16, left: 16, zIndex: 3, color: 'white', }}
                        >
                            <MenuIcon fontSize="large" style={{ color: '#fff', backgroundColor: '#dc004e' }} />
                        </IconButton>
                    </Tooltip>
                )}

                {isMobile ? (
                    <Drawer
                        anchor="left"
                        open={isMobile ? menuOpen : true}
                        onClose={toggleMenu}
                        PaperProps={{
                            sx: {
                                width: isMobile ? '230px' : '50px',
                                backgroundColor: 'transparent', // Remove o fundo do menu em telas maiores
                                boxShadow: 'none', // Remove a sombra do menu
                            },
                        }}
                        BackdropProps={{ invisible: true }} // Permite interação com o conteúdo atrás do menu
                    >
                        <SidebarMenu onOptionClick={handleMenuOptionClick} isMobile={isMobile} />
                    </Drawer>
                ) : (
                    <SidebarMenu isMobile={isMobile} onOptionClick={handleMenuOptionClick} />
                )}
                {/* Conteúdo Principal */}
                <Box sx={{ minHeight: '200%', position: 'relative', zIndex: 1, width: '100%', padding: 3, }}>
                    <Routes>
                        {AboutsConfig.map((item, index) => (
                            <Route key={index} path={item.path} element={<AboutComponent data={item} />} />
                        ))}
                        <Route path="/" element={<AboutComponent data={AboutsConfig[0]} />} />
                    </Routes>
                </Box>
            </Box>
        </Router >
    );
};

export default App;